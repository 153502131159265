<template>
  <AuctionHeader :activeSearchDesktop="false" />
  <section class="bg-agrogo-green-3 sizeMinAllView">
    <div class="relative flex flex-col bg-agrogo-home-subastas">
      <div
        class="flex flex-col space-y-2 md:pb-28 md:px-32 lg:px-72 md:space-y-4 md:pt-56"
      >
        <div class="hidden md:flex md:flex-row">
          <input
            v-model="selectedSearch"
            type="search"
            class="search-bar w-full h-14"
            placeholder="Encuentra la finca que necesitas"
          />
          <span class="icon-search-bar w-16 h-14 text-2xl ml-1"></span>
        </div>
        <div id="activateSearch" class="hidden z-50">
          <div
            class="md:flex md:flex-row bg-white px-1 pb-1 space-y-2 flex-wrap"
          >
            <div class="w-full bg-agrogo-green-2 search-home-style">
              <div v-if="searchData.length > 0" class="h-9 pt-2 bg-white">
                <p
                  class="text-xs md:text-base bg-white text-left ml-10 font-dinpro text-agrogo-gray-3"
                >
                  Se han encontrado {{ searchData.length }} resultados
                </p>
              </div>
              <div
                v-for="item in searchData"
                :key="item.id"
                class="bg-agrogo-green-5"
              >
                <div class="bg-agrogo-green-2 search-home-style">
                  <div
                    :id="'style-search-home' + item.id"
                    class="container-search-home"
                    @click="addRemoveStyleSearch(item.id)"
                  >
                    <div class="flex items-center justify-between">
                      <div class="h-20 flex items-center">
                        <div class="h-12 w-18 md:w-24 mr-5 mt-2">
                          <span
                            :class="item.icon"
                            class="ml-4 md:ml-10 mr-2 text-xl md:text-3xl"
                          ></span>
                        </div>
                        <div class="flex flex-col">
                          <p class="text-xs md:text-base text-left font-nexa">
                            {{ item.hectare }} hectareas
                          </p>
                          <p class="text-xs md:text-base text-left font-nexa">
                            {{ item.text }}, Tabio, <b>Cundinamarca</b>
                          </p>
                        </div>
                      </div>
                      <div class="w-30 mr-2 md:mr-10 text-left">
                        <p class="text-xs md:text-base text-right font-dinpro">
                          <span>ID#</span>
                          {{ item.id }}
                        </p>
                        <p
                          class="flex text-left text-base md:text-2xl font-dinpro"
                        >
                          <span
                            class="icon-signo-pesos text-sm md:text-xl mr-1 mt-1"
                          ></span>
                          {{ formatThousand(item.money) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <li
                    class="bg-white"
                    v-if="searchData.length == 0 || searchData.length == null"
                  >
                    <p
                      class="text-xs md:text-lg font-dinpro text-agrogo-gray-3"
                    >
                      No se han encontrado resultados
                    </p>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="md:flex hidden" v-if="selectedSearch.length <= 0">
          <router-link
            to="/lista-de-fincas"
            type="button"
            class="btn-primary flex items-center justify-center w-32 md:w-56 md:h-12 bg-agrogo-yellow-2 rounded-4xl md:rounded-2xl"
          >
            <span
              class="font-dinpro font-medium text-agrogo-green-2 text-xs md:text-lg"
              >Búsqueda avanzada</span
            >
          </router-link>
        </div>
      </div>
      <div class="absolute right-4 -bottom-96 md:right-7 md:-bottom-7 z-10">
        <publish-button />
      </div>
    </div>

    <!-- CARRUSEL Tipos de fincas -->
    <slider-type-animal />
    <!-- SECCION ENCUENTRA LA FINCA IDEAL PARA TI ESCRITORIO-->
    <div
      class="flex items-center justify-between bg-agrogo-yellow-2 h-8 pl-8 md:h-20 md:pl-20"
    >
      <a class="font-nexa text-agrogo-green-5 font-black text-base md:text-2xl"
        >Subastas <span class="font-nexa font-normal">Destacadas</span></a
      >
      <div class="flex pr-4 md:pr-16">
        <button
          @click="$router.push('/filtros-fincas')"
          type="button"
          class="btn-auction-filter"
        >
          Filtrar <span class="icon-filter text-3xs pl-2 md:text-xs"></span>
        </button>
      </div>
    </div>

    <!-- CARDSFARMS -->
    <div
      class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 place-content-center place-items-center gap-2 md:gap-4 px-2 pt-2 pb-6 md:px-5 md:py-4"
    >
      <router-link to="/detalle-subastas">
        <card-auction />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auction />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auction />
      </router-link>
      <router-link to="/detalle-subastas">
        <card-auction />
      </router-link>
    </div>
  </section>
  <modal-video :isModal="isModal" :url="url" @closeModal="isModal = false" />
  <modal-lead
    :isModalLead="isModalLead"
    @closeModalLead="isModalLead = false"
  />
</template>

<script>
import AuctionHeader from "@/components/header/HeaderAuction";
import SliderTypeAnimal from "@/components/slider/SliderTypeAnimal";
import CardAuction from "@/views/auction/cards/CardAuction";
import { mapState } from "vuex";
import moment from "moment";
import { generateHash } from "@/utils";
import PublishButton from "@/components/publishButton/PublishButton";
moment.locale("es");

export default {
  inject: ["provider"],
  name: "Home",
  components: {
    SliderTypeAnimal,
    CardAuction,
    AuctionHeader,
    PublishButton,
  },
  watch: {
    selectedSearch(text) {
      const activateSearch = document.getElementById("activateSearch");
      if (text.length > 0) {
        activateSearch.classList.remove("hidden");
      } else {
        activateSearch.classList.add("hidden");
      }
    },
  },
  data() {
    return {
      selected: "",
      currentDay: null,
      isModal: false,
      isModalLead: false,
      url: null,
      selectedSearch: "",
      repository: this.provider.homeRepository,
      items: ["foo", "bar", "fizz", "buzz"],
      values: ["foo", "bar"],
      searchData: [
        {
          id: 1,
          icon: "icon-reforestacion",
          text: "reforestacion",
          money: 10000000,
          hectare: 400,
        },
        {
          id: 2,
          icon: "icon-agricola",
          text: "agricola",
          money: 3000000,
          hectare: 400,
        },
        {
          id: 3,
          icon: "icon-porcicola",
          text: "porcicola",
          money: 2040000,
          hectare: 2400,
        },
        {
          id: 4,
          icon: "icon-virgen",
          text: "virgen",
          money: 120500000,
          hectare: 100,
        },
        {
          id: 5,
          icon: "icon-minera",
          text: "minera",
          money: 3300000,
          hectare: 460,
        },
        {
          id: 6,
          icon: "icon-minera",
          text: "minera",
          money: 550000,
          hectare: 460,
        },
        {
          id: 7,
          icon: "icon-minera",
          text: "minera",
          money: 20000000,
          hectare: 460,
        },
        {
          id: 8,
          icon: "icon-minera",
          text: "minera",
          money: 2200000,
          hectare: 460,
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
    resultsFilter() {
      return this.searchData.filter((data) => {
        return data.includes(this.selected);
      });
    },
  },
  methods: {
    async timezone() {
      const me = this;
      try {
        let diff = 0;
        const { data } = await me.repository.timezone();
        me.currentDay = moment(data);

        if (localStorage.getItem("displayDate")) {
          const displayDate = moment(localStorage.getItem("displayDate"));
          diff = me.currentDay.diff(displayDate, "days");
        }

        return diff;
      } catch (error) {
        // console.log(error);
      }
    },
    //AGREGA O ELIMINA EL DISEÑO AL MOMENTO DE SELECCIONAR EL ITEM
    addRemoveStyleSearch(id) {
      const me = this;
      const contentSearch = document.getElementById("style-search-home" + id);
      if (contentSearch.classList[1]) {
        //ELIMINA LA CLASE DEL ELEMENTO SELECCIONADO
        contentSearch.classList.remove("active-container-search-home");
      } else {
        me.removeAllStyleSearch();
        //AGREGA LA CLASE AL ELMENTO SELECCIONADO
        contentSearch.classList.add("active-container-search-home");
      }
    },
    //ELIMINA LA CLASE PARA TODOS LOS ELEMENTOS
    removeAllStyleSearch() {
      const me = this;
      me.searchData.forEach((element) => {
        var content_search = document.getElementById(
          "style-search-home" + element.id
        );
        if (content_search.classList[1]) {
          content_search.classList.remove("active-container-search-home");
        }
      });
    },
    async loadVideoORimage() {
      const me = this;
      try {
        const dataAditional = await generateHash();

        const name = "MODAL_MAIN";
        // const name = "modal-video-1";
        // const data1 = " modal-prueba-frontend";
        const {
          data: { data },
        } = await me.repository.find(
          {
            ...dataAditional,
            dev0: process.env.VUE_APP_DEBUG_CUSTOM,
          },
          name
        );
        const urlImg = `https://s3.us-east-2.amazonaws.com/media.fincas.agrogo.com.co${data.url_media}`;
        me.url = { ...data, urlImg };
      } catch (error) {
        // console.log(error);
      }
    },
    async loadModal() {
      const me = this;
      const diff = await me.timezone();

      await me.loadVideoORimage();
      //Display modal
      const display = localStorage.getItem("display");

      if (!display) {
        localStorage.setItem("displayDate", me.currentDay.format("YYYY-MM-DD"));
        localStorage.setItem("display", "one");
        me.isModal = true;
        return;
      }

      if (display == "one") {
        localStorage.setItem("display", "two");
        me.isModal = true;
      } else {
        if (diff >= 3) {
          localStorage.setItem(
            "displayDate",
            me.currentDay.format("YYYY-MM-DD")
          );
          localStorage.setItem("display", "one");
          me.isModal = true;
        }
      }
    },
    async loadModalLead() {
      const me = this;
      const diff = await me.timezone();

      //Display modal
      const display = localStorage.getItem("displayLeads");

      if (!display) {
        localStorage.setItem(
          "displayDateLeads",
          me.currentDay.format("YYYY-MM-DD")
        );
        localStorage.setItem("displayLeads", "one");
        me.isModalLead = true;
        return;
      }

      if (display == "one") {
        localStorage.setItem("displayLeads", "two");
        me.isModalLead = true;
      } else {
        if (diff >= 3) {
          localStorage.setItem(
            "displayDateLeads",
            me.currentDay.format("YYYY-MM-DD")
          );
          localStorage.setItem("display", "one");
          me.isModalLead = true;
        }
        // Validación de 7 días si completa el filtro
      }
    },
    //DA FORMATO DE PUNTOS DE MIL
    formatThousand(number) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        currency: "COP",
        minimumFractionDigits: 0,
      });
      number = formatterPeso.format(number);
      return number;
    },
  },
  mounted() {
    const me = this;
    if (me.isAuthenticated) {
      me.loadModal();
    } else {
      me.loadModalLead();
    }
  },
};
</script>

<style lang="css" scoped></style>
